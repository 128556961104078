::-moz-selection {
  color: white;
  background: #b21e1e;
}
::selection {
  color: white;
  background: #b21e1e;
}

textarea {
  font-family: arial;
  resize: vertical;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

html {
  background-color: #1a1919;
}

/** Header Styling **/
.header {
  display: flex;
  height: 4vh;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #1a1919;
  align-content: center;
}

.logo {
  position: absolute;
  left: 1em;
  top: 1em;
  width: 20%;
}

.headerRight {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 20px;
  padding-top: 2%;
  cursor: pointer;
  transition: 0.3s;
}
.headerRight:hover {
  color: #b21e1e;
}
/**End of Header Styling **/

/**Dashboard Menu Styling **/
@media screen and (max-width: 780px) {
  .nav {
    display: none;
  }
}

.nav .navMenuContainer {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav li {
  width: 33%;
  margin: 0 5%;
  cursor: pointer;
}

.nav li:hover {
  background-color: #dfdbdb;
}

.nav .tabText {
  width: 33%;
  text-align: -webkit-center;

  margin: 0 5%;
  color: black;
  padding: 0.3em;
  transition: 0.3s;
  border-radius: 25px;
  user-select: none;
  -webkit-user-select: none;
}

.nav .tabTextActive {
  border-bottom: solid 2px #a60101;
}

.editProfileDropdown .dropbtn {
  font-size: 16px;
  border: none;
  background-color: inherit;
  font-family: inherit;
}

.editProfileDropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: -webkit-fill-available;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 99;
}

.editProfileDropdownContent a {
  padding: 12px 16px;
  display: block;
  text-align: left;
  transition: 0.3s;
}

.editProfileDropdownContent a:hover {
  background-color: #ddd;
}

.editProfileDropdown:hover .editProfileDropdownContent,
.editProfileDropdownContent:hover {
  display: block;
  transition-delay: 5s;
}

.editProfileDropdown .tabTextActive {
  background-color: rgb(185, 185, 185);
  border: none;
}
/**End of Dashboard Menu Styling **/

/**Mobile Dashboard Menu Styling **/
.mobileNav {
  display: none;
  color: white;
}

@media screen and (max-width: 780px) {
  .mobileNav {
    display: unset;
  }
}

/**Mobile Menu Button Styling **/
.mobileNav .dropButton {
  background-color: white;
  color: black;
  padding: 16px;
  font-size: 16px;
  width: 100%;
  border-radius: 25px;
  border: 0;
  transition: 0.3s;
}
.mobileNav .dropButton:hover {
  cursor: pointer;
  background-color: #dfdbdb;
}

.mobileNav .dropButtonActive {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.mobileNav .arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
/**End of Mobile Menu Button Styling **/

/**Mobile Menu Container Styling **/
.mobileNav .menuContainer {
  display: none;
  background-color: white;
  margin: 0;
  padding: 1%;
  list-style: none;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
}

.mobileNav .menuContainerActive {
  display: block;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.mobileNav li {
  box-sizing: border-box;
  width: 100%;
  left: 0;
  list-style: none;
  cursor: pointer;
  padding: 12px 16px;
  display: block;
  text-align: left;
  color: black;
  transition: 0.3s ease-in-out;
}
.mobileNav li:not(:last-child):hover {
  background-color: #dfdbdb;
}
.mobileNav .tabTextActive {
  background-color: rgb(185, 185, 185);
}

.mobileNav .editProfileDropdown .dropbtn {
  font-size: 16px;
  border: none;
  color: black;
  background-color: inherit;
  font-family: inherit;
}

.mobileNav .editProfileDropdownContent a:hover {
  cursor: pointer;
}

.mobileNav .editProfileDropdownContent {
  min-width: fit-content;
  position: unset;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%);
}
/**End of Mobile Menu Container Styling **/

/**Dashboard styling **/
.dashboard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 25px;
  padding: 5% 2%;
  width: 80%;
  height: 70vh;
  overflow: hidden;
}
.dashboard.authPage {
  overflow-y: scroll;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media screen and (max-width: 780px) {
  .dashboard {
    width: 90%;
  }
}

.dashboardMain {
  margin-top: 2em;
  height: 80%;
  overflow-y: scroll;
}
/**End of Dashboard  Styling **/

/**Form styling **/
/**Input Field Styling **/
input {
  transition: 0.3s ease-in-out;
}
input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgb(255 150 150 / 27%) !important;
}
.textField {
  margin-bottom: 10px;
  display: flex;
  box-shadow: rgb(99 99 99 / 15%) 0px 2px 10px 0px;
}

.textField input {
  border: none;
  min-width: -webkit-fill-available;
  font-size: 1em;
  padding: 1em;
}

.password input {
  min-width: 80%;
}

.textField span {
  padding: 1em;
  font-size: 18px;
}

.textField:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}
/**End of Input Field Styling **/

/**Button Styling **/
.button {
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  width: -webkit-fill-available;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 18px;
  border: none;
  padding: 1em;
  transition: 0.3s;
}

.button:hover {
  background-color: rgb(132 28 28);
}
/**End of Button Styling **/

/**No Account Container Styling **/
.noAccountContainer {
  justify-content: center;
  display: flex;
}

.noAccountContainer a {
  color: rgb(0, 0, 0);
  font-weight: bold;
  padding-left: 5px;
}
/**End of No Account Container Styling Styling **/
/**End of Form styling Styling **/

.formTextField, .react-datepicker__input-container input{
  width:90%;
  padding:2%;
  border-radius:25px;
  margin-bottom:2%;
  border: 1px solid #d8d8d8;
}

.dropDown{
  width:95%
}

.upload {
  width: 90%;
  margin-bottom: 1em;
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 0.5em 1em 1em 1em;
  box-sizing: border-box;
}
.upload input {
  width: 90%;
  padding: 1em;
  border-radius: 25px;
  text-align-last: center;
}
.upload label {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  text-align: center;
}
.upload small {
  display: inline-block;
  width: 100%;
  font-style: italic;
  text-align: center;
}

.tab-book-call iframe,
.tab-book-test-shoot iframe,
.tab-book-live-shoot iframe {
  width: 100%;
  height: 60vh;
}

.react-datepicker__input-container {
  position: unset !important;
}


.selectInput {
  appearance: none;
  position: relative;
  width: 93%;
}
.selectInput::before {
  content: "❯";
  display: inline-block;
  position: absolute;
  top: 0.6em;
  right: 1em;
  color: black;
  transform: rotate(90deg);
  transition: 0.3s ease-in-out;
}
.selectInput.active::before {
  /* TBI */
  transform: rotate(90deg);
}
.selectInput select {
  width: 100%;
}

.formTextField {
  border: 1px solid #d8d8d8;
  margin-bottom: 1em;
  padding: 1em;
}

.numberFieldContainer {
  position: relative;
}
.numberFieldContainer small {
  position: absolute;
  left: 5em;
  top: 25%;
  line-height: 1em;
  color: grey;
  pointer-events: none;
}

.message-warning {
  text-align: center;
  color: #b21e1e;
}

.overview-links {
  padding: 0;
}
.overview-link {
  display: block;
  width: 80%;
  margin: auto;
  margin-bottom: 1em;
  padding: 1em;
  color: black;
  text-decoration: none;
  background-color: #fcfcfc;
  border-left: 5px solid #aa0101;
  border-radius: 10px;
  box-shadow: rgb(9 9 9 / 23%) 6px 5px 20px 0px;
  transition: 0.3s ease-in-out;
}
.overview-link:hover {
  color: #aa0101;
  background-color: #eeeeee;
}
.overview-link-text {
  position: relative;
}
.overview-icon {
  /*
  margin-left: 1em;
  border: 2px solid #aa0101;
  padding: 0.2em;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-weight: bold;
  right: 61%;
  color: #aa0101;
  */
  margin-left: 1em;
  font-size: 1em;
  font-weight: bold;
  color: #b21e1e;
}
.dashboard h2, .dashboard h3 {
  text-align: center;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(#b21e1e, #333);
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(#911818, #222);
}

form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.react-datepicker-wrapper,
.numberFieldContainer {
	width: 93% !important;
}

.react-datepicker-wrapper input,
.numberFieldContainer input {
	width: 100%;
	box-sizing: border-box;
}
input[type="submit"] {
  margin-top: 1.5em;
}
.react-datepicker-wrapper input {
  padding: 1em;
}
.react-datepicker-wrapper input,
.dashboard select,
.dashboard textarea {
  box-shadow: rgb(99 99 99 / 15%) 0px 2px 10px 0px;
}

.dropButton i {
	transition: 0.3s ease-in-out;
}
.dropButton.dropButtonActive i {
	transform: rotate(45deg);
}

/*
.mobileNav .menuContainer {
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: 0.3s ease-in-out;
}
.mobileNav .menuContainerActive {
  height: 10em !important;
}
*/


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 90%;
  height: 100%;
  top: 0;
}
.loader .ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader .ripple div {
  position: absolute;
  border: 4px solid #b21e1e;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader .ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.authForm .textField {
  width: 90%;
}
.iconVisible {
  -webkit-user-select: none;
  user-select: none;
  transition: 0.3s;
}
.iconVisible:hover {
  color: #b21e1e;
}
.authPage h1 {
  text-align: center;
}
a.disabled {
  color: grey;
  pointer-events: none;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.overview-icon {
    height: 25px;
    width: 25px;
    border-radius: 25px;
    text-align: center;
    box-shadow: black;
    margin-right: 15px;
    box-shadow: rgb(0 0 0 / 50%) 0px 0px 10px;
}

.overview-icon p{
  margin:0;
  padding:0;
  font-size: larger;
  transform: scale(2);
}

.overview-link-text {
  display: flex;
  width: 100%;
  align-items: center;
}
.overview-link-label {
  margin-left: 0.8em;
}

.socialOverview{
  display: flex;
  justify-content: center;
}

.socialOverview a{
  text-decoration: none;
  color: #333;
}

.socialOverview a:hover{
  color: blue;
}

.socialOverview p{
  color: gray;
}

.row{
  display: flex;
}
.row > div{
  margin: 5px;
}

.gray-line {
  border-top: 1px solid gray;
  height: 1px;
  width: 100%;
  margin-top: 20px;
  
  
  }

@media (max-width: 900px) {
  .flex-container {
    flex-direction: column;
    justify-content: center;
    flex: 1;

  }
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800|Poppins&display=swap');
.flex-container .card {
  flex: 1;
  background-color: #f1f1f1;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  border-top: 5px solid #b21e1e;
  box-shadow: 0px 25px 20px -20px hsl(229, 6%, 66%);

}

@media (max-width: 800px) {
  .flex-container .percentageDifference{
    flex-direction: row;
    font-size: 24px;
    justify-content: center;
    align-items: center;

  }
}


.flex-container .copyLink button{
  background-color: none;
  color: black;
  border: 2px solid #b21e1e;
  margin: 10px;
  margin-left: 20px;
  justify-content: flex-end;
  padding: 10px;
  border-radius: 20px;
  font-weight: 700;
}

.flex-container .copyLink button:hover{
  background-color: #b21e1e;
  color: white;
  border: 2px solid #b21e1e;
 
}
.flex-container .copyLink{
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  
}

.flex-container .copyLink a{
  margin: 5px;
  margin-top: 10px;
  font-size: 15px;
  color: #1a1919;

}

.flex-container .copyLink a:hover{
  color: blue;

}

.flex-container .copyLink .icon{
  margin-top: 0px;
  
}


.link {
  cursor: pointer;
  transition: 0.3s;
}
.link:hover {
  color: #b21e1e;
}
.checkbox .link {
  text-decoration: none;
  color: #b21e1e;
}
.checkbox .link:hover {
  color: #e81e1e;
}

/* Your Stats CSS */
.stats{
  justify-content: center;
  
}



.percentage{
  color: #47d627 ;
}
/* --- */

.btnBottomNav {
  text-decoration: none;
  color: #b21e1e;
  margin: 1em 2em;
  font-weight: bold;
  transition: 0.3s;
}
.btnBottomNav:hover {
  color: #e81e1e;
}
.bottomNavBtns {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

input[type="file"] {
  display: none;
}

.btnSelectFile {
  display: block;
  width: 90%;
  margin: auto;
  margin-top: 1em;
  padding: 1em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.btnSelectFile:hover {
  background-color: #dfdbdb;
}
.react-datepicker select {
  padding: 0.2em 0.5em;
  margin-top: 0.5em;
  margin-right: 0.5em;
  box-shadow: none;
}



.socialOverview {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.socialOverview a {
  text-decoration: none;
  color: #333;
}

.socialOverview a:hover {
  color: blue;
}

.socialOverview p {
  color: gray;
}

.row {
  display: flex;
}
.row > div {
  margin: 5px;
}

.gray-line {
  border-top: 1px solid gray;
  height: 1px;
  width: 100%;
  margin-top: 20px;


  }

@media (max-width: 800px) {
  .flex-container .card {
    width: 70%;
  }
  .socialStats .card {
    width: auto !important;
  }
  .flex-container .copyLink {
    flex-wrap: wrap;
    justify-content: center !important;
  }
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800|Poppins&display=swap');
.flex-container .card {
  background-color: #f1f1f1;
  margin: 1em;
  padding: 10px;
  border-radius: 10px;
  border-top: 5px solid #b21e1e;
  box-shadow: 0px 30px 40px -20px hsl(229, 6%, 66%);
}
.flex-container .copyLink button {
  background-color: none;
  color: black;
  border: 2px solid #b21e1e;
  margin: 10px;
  margin-left: 20px;
  justify-content: flex-end;
  padding: 10px;
  border-radius: 20px;
  font-weight: 700;
}

.flex-container .copyLink button:hover {
  background-color: #b21e1e;
  color: white;
  border: 2px solid #b21e1e;

}
.flex-container .copyLink {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.3em;
}

.flex-container .copyLink a {
  display: flex;
  margin: 0.3em;
  font-size: 15px;
  color: #1a1919;
}

.flex-container .copyLink a svg {
  margin-right: 0.3em;
}

.flex-container .copyLink .icon {
  margin-top: 0px;
}

.card .title {
  flex-direction: column-reverse;
  align-items: center;
}
.card h2 {
  margin-top: 0;
}

.grid-container{
  display: grid;
  grid-template-columns: repeat(4,  0.75fr);
  grid-template-columns: repeat(auto-fill);
}

.socialStats .row{
  height: 1%;
}


.socialOverviewModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0px;
  background-color: #80808078;
}
.socialOverviewModal .modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em 2em;
  background-color: white;
  border-radius: 10px;
}
.socialOverviewModal .modalContent .closeButton {
  display: flex;
  align-self: flex-end;
  font-size: 1.5em;
  padding: 0.2em;
  color: grey;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
}
.socialOverviewModal .modalContent .closeButton:hover {
  background-color: #dfdbdb;
}
.socialOverviewModal .modalContent ul {
  list-style: none;
  padding-left: 0;
}
.socialOverviewModal .modalContent ul li {
  margin-bottom: 1em;
  font-weight: bold;
  color: white;
  background-color: maroon;
  border-radius: 25px;
  transition: 0.3s;
}
.socialOverviewModal .modalContent ul li:hover {
  background-color: rgb(109, 2, 2);
}
.socialOverviewModal .modalContent ul li button {
  display: flex;
  padding: 0.5em 2em !important;
  text-align: left;
}
.socialOverviewModal .modalContent ul li button svg {
  font-size: 1.5em;
  margin-right: 0.5em;
}

.socialOverview .liveLinkBtn {
  transition: 0.3s;
}
.socialOverview .liveLinkBtn:hover {
  color: #b21e1e;
}
.socialOverview .shareBtn {
  transition: 0.3s;
  cursor: pointer;
}
.socialOverview .shareBtn:hover {
  background-color: #b21e1e;
}
.disclaimer {
  padding: 2em;
  text-align: justify;
  background: #f1f1f1;
  margin: 2em;
  margin-top: 0;
  border-radius: 10px;
  font-weight: bold;
  width: 80%;
  margin: auto;
  margin-bottom: 2em;
}
/*
.socialOverview .disclaimer.alert {
  color: #a02121;
}
*/